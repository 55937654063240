.palns-container {
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
    
.plan {
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: #ffff;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}

.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
    transition: all 0.3s;
}

.plan:nth-child(2):hover{
    transition: all 1s;
    transform: scale(1.12);
    border-radius: 5px;
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.3);
}


.plan > svg {
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.plan > :nth-child(2) {
    font-size: 1rem;
    font-weight: bold;

}

.plan > :nth-child(3) {
    font-size: 3rem;
    font-weight: bold;  
}

.plan > :nth-child(5) {
    font-size: 0.8rem;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.feature {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature > img {
    width: 1rem;
}

.plans > :nth-child(2) > svg {
    fill: white;
}

.plans > :nth-child(2) > button {
    color: #FFA500;
}

.plans-blur-1 {
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}

.plans-blur-2 {
    width: 32rem;
    height: 23rem;
    top: 6rem;
    right: 0rem;
}

.programs-header {
    gap: 2rem;
}

@media screen and (max-width : 768px) {
    .plans {
        flex-direction: column;
        gap: 1rem;
    }

    .plan:nth-child(2){
        transform: none;
    }

    .plan:nth-child(2):hover{
        transition: all 1s;
        transform: none;
        border-radius: 5px;
        box-shadow: none;
    }

    .palns-container {
        margin-top: -5rem;
    }

    .plans {
        margin-top: -2rem;
    }
}