.footer-container {
    position: relative;
}

.footer-container > hr {
    border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links {
    display: flex;
    gap: 4rem;
    font-size: 2rem;
    color: #ffff;
    transition: all 0.3s;
    cursor: pointer;
}

.social-links > :nth-child(1),
.social-links > :nth-child(2),
.social-links > :nth-child(3) {
    border: 2px solid #ffffff74;
    border-radius: 50%;
    padding: 10px;
    transition: all 0.3s;
}

.social-links > :nth-child(1):hover,
.social-links > :nth-child(2):hover,
.social-links > :nth-child(3):hover {
    border: 2px solid #F48915;
    transition: all 0.3s;
    box-shadow: 0px 0px 23px 1px rgba(244, 137, 21, 0.2);
    transform: scale(0.9);
}

.logo-f {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-f > img {
    width: 10rem;
}

.blur-f-1 {
    bottom: 0;
    right: 15%;
    width: 36rem;
    height: 12rem;
    filter: blur(210px);
    background-color: #FF0000;
}

.blur-f-2 {
    bottom: 0;
    left: 15%;
    width: 36rem;
    height: 12rem;
    filter: blur(210px);
    background-color: rgb(255,115,0);
}

.copyright , a {
    font-size: 1rem;
    color: var(--lightgray);
    text-decoration: none;
    align-items: center;
}

.copyright > a {
    color: #F48915;
}

@media screen and (max-width : 768px) {
    .footer-container {
        margin: -10rem;
    }

    .social-links {
        margin-top: -8rem;
    }
    
    .logo-f {
        margin-top: -2rem;
    }

    .footer-container > hr {
        display: none;
    }
}