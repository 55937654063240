.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 10rem;
    height: 3rem;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    color: #ffff;
}

.header-menu li {
    cursor: pointer;
    transition: all 0.3s;
}

.header-menu li:hover {
    color: var(--orange);
    cursor: pointer;
    transition: all 0.3s;
}

.bars-icon {
    width: 1.5rem;
    height: 1.25rem;
    margin-top: 2px;
}

.bars {
    background-color: var(--appColor);
    padding: 0.5rem;
    border-radius: 5px;
}

.close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    font-size: 2rem;
}

@media screen and (max-width : 768px) {
    .header > :nth-child(2) {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }

    .header-menu {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.2);
    }
}