.join {
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j {
    color: #ffff;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j > hr {
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 30%;
    margin: -10px 0;
}

.left-j > div {
    display: flex;
    gap: 1rem;
}

.right-j {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container {
    display: flex;
    gap: 3rem;
    background-color: #808080;
    padding: 1rem 2rem;
    border-radius: 1px;
    position: absolute;
    right: 20rem;
}

.email-container > input {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
 
}

::placeholder {
    color: var(--lightgray);
}

.btn-j {
    background-color: var(--orange);
    color: #ffff;
}

.btn-j:hover {
    background-color: var(--orange);
    color: #ffff;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transform: scale(1.1);
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
    transition: all 0.3s;

}

@media screen and (max-width : 768px) {
    .join {
        flex-direction: column;
        gap: 4rem;
        margin-top: -2rem;
    }

    .left-j {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: large;
        flex-direction: column;
    }

    .right-j {
        padding: 3rem;
    }

    .email-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        position: absolute;
        right: 0;
        left: 0;
        padding: 10px;
    }

    .left-j > hr {
        top: -1.5rem;
    }
    
}